import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';
import CurrentStepIndicator from '../components/CurrentStepIndicator';
import LinkButton from '../components/LinkButton.jsx';
import './Index.scss';

function AnnouncementBanner(props) {
  const { t } = useTranslation();

  /* 
    <Trans i18nKey="description.part1"></Trans>
    {t("description.part2")}
  */
  return (
    <>
      <Link to="/announcement" className="no-underline">
        <div id="AnnouncementBanner">
          <h4 className="Announcement-Title">
            {t('page.index.announcement.covid19.title')}{' '}
            <svg className="icon icon-arrow-right">
              <use xlinkHref="#icon-arrow-right"></use>
            </svg>
          </h4>
        </div>
      </Link>
    </>
  );
}

function Page(props) {
  const [announcement, setAnnouncement] = useState([]);
  const { REACT_APP_SHOW_ANNOUNCEMENT } = process.env;

  useEffect(() => {
    function fetchData() {
      const { REACT_APP_API_URL } = process.env;
      const endpoint = `${REACT_APP_API_URL}/wp/v2/announcements`;
      console.log(`Fetching announcements from ${endpoint}`);
      return axios.get(endpoint);
    }

    fetchData()
      .then(data => {
        setAnnouncement(data[0]);
      })
      .catch(error => {
        console.error(error);
      });

    return () => {};
  }, []);

  return (
    <>
      {REACT_APP_SHOW_ANNOUNCEMENT && <AnnouncementBanner post={announcement} />}

      <div className="wrapper">
        <article className="text-flow content">
          <h1>
            Hej!
            <br />
            Välkommen till skrufs återförsäljarreturer
          </h1>

          <p>
            Minst 30 st... Lorem ipsum dolor sit amet consectetur adipisicing elit. Delectus ducimus
            distinctio enim, aperiam exercitationem repudiandae, ipsam placeat cumque obcaecati sed
            modi reiciendis nulla consequuntur. Nulla, optio doloremque? Nisi, facilis corporis!
          </p>
          <p>I tre enkla steg...</p>
        </article>

        <CurrentStepIndicator></CurrentStepIndicator>

        <div className="flex-center">
          <LinkButton to="/returns" className="secondary-button">
            Till returer
          </LinkButton>
        </div>
      </div>
    </>
  );
}

export default withRouter(Page);
