import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import './LanguageSelector.scss';
import LinkButton from './LinkButton';

const lngs = {
  sv: { nativeName: 'Svenska' },
  en: { nativeName: 'Engelska' },
  ar: { nativeName: 'Arabiska' },
};

const lngs_simple = {
  en: { nativeName: 'Engelska', nativeNameMobile: 'ENG', to: '/english' },
  ar: { nativeName: 'Arabiska', nativeNameMobile: 'ARA', to: '/arabic' },
};

export default function Component(props) {
  const { i18n } = useTranslation();

  const isMobileSized = useMediaQuery({ query: `(max-width: 640px)` });

  if (props.simple === true)
    return (
      <>
        <menu id="LanguageSelector">
          {Object.keys(lngs_simple).map(lng => (
            <LinkButton key={lng} to={lngs_simple[lng].to}>
              {isMobileSized ? lngs_simple[lng].nativeNameMobile : lngs_simple[lng].nativeName}
            </LinkButton>
          ))}
        </menu>
      </>
    );
  else
    return (
      <>
        <menu id="LanguageSelector">
          {Object.keys(lngs).map(lng => {
            if (i18n.language === lng) return null;
            return (
              <button
                key={lng}
                style={{ textDecoration: i18n.language === lng && 'underline' }}
                type="submit"
                onClick={() => i18n.changeLanguage(lng)}
              >
                {lngs[lng].nativeName}
              </button>
            );
          })}
        </menu>
      </>
    );
}
