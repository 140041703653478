import React from "react";
import "./ReportSubmitted.scss";

function ReportSubmitted() {
  return (
    <div className="wrapper">
      <div className="ReportSubmitted">
        <h2>Tack! Din retur är registrerad</h2>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Expedita,
          obcaecati in modi porro fuga aperiam praesentium pariatur corporis.
          Aperiam nostrum architecto minima eaque, sit quidem!
        </p>
        <p>Välkommen åter.</p>
      </div>
    </div>
  );
}

export default ReportSubmitted;
