import React from 'react';
import CurrentStepIndicator from '../components/CurrentStepIndicator';
import ProductList from '../components/ProductList';

// import './Index.scss';

function Page() {
  return (
    <div className="wrapper">
      <>
        <CurrentStepIndicator step={1}></CurrentStepIndicator>
        <ProductList />
      </>
    </div>
  );
}

export default Page;
