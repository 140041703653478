import React, { createContext, useContext, useEffect, useReducer, useState } from 'react';

export const CartContext = createContext([]);

function reducer(state, action) {
  const { item } = action;
  switch (action.type) {
    case 'ADD_ITEM':
      const { add_amount, add_index } = action;
      let add_result = [...state, { count: add_amount, ...item }];
      if (add_index !== null) {
        state[add_index].count += add_amount;
        add_result = state;
      }
      console.log(
        `Want to build state with new object: `,
        { count: add_amount, ...item },
        `and old state:`,
        state,
        `add_result would be`,
        add_result,
      );
      return add_result;

    case 'REMOVE_ITEM':
      const { remove_amount, remove_index } = action;
      let remove_result = state.filter(product => {
        return product.id !== item.id;
      });
      if (remove_amount !== null) {
        state[remove_index].count -= remove_amount;
        if (state[remove_index].count > 0) remove_result = state;
      }
      return remove_result;

    case 'SET_ITEM_COUNT':
      const { count, edit_index } = action;
      state[edit_index].count = count;
      return state;

    case 'DESTROY_CART':
      return [];

    default:
      throw new Error();
  }
}

export default function CartProvider(props) {
  const verifyState = state => {
    if (!state) return state;
    const newState = state.filter(product => {
      return product.id;
    });
    console.log(
      'verifying incoming state',
      state,
      `result: states were ${newState === state ? 'similar' : 'different'}`,
    );
    return newState;
  };

  const cachedItems = verifyState(JSON.parse(sessionStorage.getItem('cart-items'))) || [];
  console.log('Loaded cached items:', cachedItems);

  const [state, dispatch] = useReducer(reducer, cachedItems);
  const [wasAdded, setWasAdded] = useState(false);

  console.log('state is', state);

  const removeItem = (itemToRemove, amount = null) => {
    console.log(`Removing item:`, { itemToRemove });

    dispatch({
      type: 'REMOVE_ITEM',
      item: itemToRemove,
      remove_amount: amount,
      remove_index: indexInCart(itemToRemove),
    });

    // Upon addItem, set was added boolean also (will trigger some animations)
    if (!wasAdded) {
      setWasAdded(true);
      setTimeout(() => {
        setWasAdded(false);
      }, 1000);
    }
  };

  const addItem = (itemToAdd, amount = 1) => {
    console.log(`adding item... ${amount} *`, itemToAdd);

    dispatch({
      type: 'ADD_ITEM',
      item: itemToAdd,
      add_amount: amount,
      add_index: indexInCart(itemToAdd),
    });

    // Upon addItem, set was added boolean also (will trigger some animations)
    if (!wasAdded) {
      setWasAdded(true);
      setTimeout(() => {
        setWasAdded(false);
      }, 1000);
    }
  };

  const getItemCount = () => {
    if (state.length < 1) return 0;
    let itemCount = 0;
    state.forEach(a => {
      let { count } = a;
      itemCount += count;
    });
    return itemCount;
  };

  const setCount = (item, count) => {
    dispatch({
      type: 'SET_ITEM_COUNT',
      item,
      count,
      edit_index: indexInCart(item),
    });

    // Upon addItem, set was added boolean also (will trigger some animations)
    if (!wasAdded) {
      setWasAdded(true);
      setTimeout(() => {
        setWasAdded(false);
      }, 1000);
    }
  };

  const destroyCart = () => {
    dispatch({ type: 'DESTROY_CART' });
  };

  const values = {
    add: addItem,
    remove: removeItem,
    getItemCount,
    destroyCart,
    inCart,
    amountInCart,
    setCount,
    items: state,
    wasAdded,
  };

  /**
   * Helper function to see where current product is within cart
   * @param {item} item
   * @returns {integer} array index of state
   */
  function indexInCart(item) {
    let result = null;
    state.forEach((a, b) => {
      if (item.id === a.id) {
        result = b;
        return false;
      }
    });
    return result;
  }

  /**
   * Helper function to see if current product is within cart
   * @param {item} item
   * @returns {boolean}
   */
  function inCart(item) {
    if (state.length > 0) {
      return state.some(p => {
        return p.id === item.id;
      });
    } else {
      return false;
    }
  }

  // Helper function to see how many of current product is within cart
  function amountInCart(item) {
    if (state.length > 0) {
      let result = state.filter(p => {
        return p.id === item.id;
      });

      if (result.length > 0) {
        return result[0].count;
      }
    }
    return 0;
  }

  // When state changes, handle the sessionStorage
  useEffect(() => {
    if (state.length > 0) {
      sessionStorage.setItem('cart-items', JSON.stringify(state));
    } else if (sessionStorage.getItem('cart-items')) {
      sessionStorage.removeItem('cart-items');
    }
  }, [state]);

  return <CartContext.Provider value={values}>{props.children}</CartContext.Provider>;
}

export const CartConsumer = CartContext.Consumer;
export const useCart = () => useContext(CartContext);
