import React from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { isSafari } from 'react-device-detect';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import * as serviceWorker from './serviceWorker';

//STEP 1:
//create components using React.lazy
const SafariStyle = React.lazy(() => import('./safari'));

//STEP 2:
//create a parent component that will load the components conditionally using React.Suspense
const ThemeSelector = ({ children }) => {
  return (
    <>
      <React.Suspense fallback={<></>}>{isSafari && <SafariStyle />}</React.Suspense>
      {children}
    </>
  );
};

ReactDOM.render(
  <ThemeSelector>
    <App />
  </ThemeSelector>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
