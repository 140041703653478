import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import CartItem from '../components/CartItem';
import LinkButton from '../components/LinkButton';
import { useCart } from '../contexts/CartContext';
import './Checkout.scss';
import Loader from './shared/Loader';
import { ErrorDescription } from './shared/Styled';

/**
 * Renders the whole CartList along with cartitems etc.
 */
function Checkout(props) {
  // Which contexts to consume
  const { items } = useCart();
  const sorted = getSortedItemsByGroup();

  // Several states
  const [isError] = useState(false);
  const [isLoading] = useState(false);

  /**
   * Function sorts cart array into object with properties by product group
   * @returns object
   */
  function getSortedItemsByGroup() {
    return items.reduce((groups, item) => {
      const pGroup = item.product_group;

      if (!groups[pGroup]) groups[pGroup] = [];

      groups[pGroup].push(item);
      return groups;
    }, {});
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className={`${props.simple ? 'simple' : ''} cartPage`}>
      {items.length <= 0 ? (
        <>
          <h2 className="PageTitle">Din returkorg är tom</h2>
          <Link to="/returns" className="center-block">
            Tillbaka till produkterna
          </Link>
        </>
      ) : (
        <>
          <h2 className="PageTitle hidden">Din returkorg</h2>
          <div className="CartList">
            {Object.keys(sorted).map(function (key, index) {
              let items = sorted[key];

              return (
                <React.Fragment key={key}>
                  {index !== 0 && <hr className="CartGroupDivider" />}
                  {items.map(item => {
                    return (
                      <CartItem
                        simple={props.simple}
                        key={item.id}
                        itemGroup={item.product_group}
                        itemName={item.product_name}
                        itemId={item.id}
                        itemData={item}
                      />
                    );
                  })}
                </React.Fragment>
              );
            })}
          </div>
          {!props.simple && (
            <footer className="CartList-footer">
              {isError && (
                <ErrorDescription>
                  Det skedde fel i formuläret, felen har markerats ovan.
                </ErrorDescription>
              )}

              <div className="flex-center">
                <LinkButton disabled={isLoading} className="outlined-button" to="/returns">
                  Tillbaka till produkterna
                </LinkButton>
                <LinkButton
                  type="submit"
                  disabled={isLoading}
                  className="secondary-button"
                  to="/submit"
                >
                  Nästa steg
                </LinkButton>
              </div>
            </footer>
          )}
        </>
      )}
    </div>
  );
}

export default Checkout;
