import React from 'react';

// When an route is not found, render this component
function Page() {
  return (
    <div className="wrapper page">
      <div className="text-flow content">
        <h1>In Arabic</h1>
        <p className="preamble">
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dicta recusandae qui itaque,
          molestiae debitis perferendis impedit nisi quibusdam culpa adipisci inventore.
        </p>

        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dicta numquam totam sapiente
          accusamus repellendus nobis, voluptatum natus. Velit, quaerat praesentium deserunt aliquam
          cupiditate ad, recusandae ut fugit placeat deleniti, aliquid beatae eum maiores quasi
          omnis cum debitis. Perferendis, ipsum porro natus fuga?
        </p>
      </div>
    </div>
  );
}

export default Page;
