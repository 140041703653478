import React from 'react';

import './CurrentStepIndicator.scss';

function CurrentStepIndicator(props) {
    return (
        <div id="CurrentStepIndicator">
            <div className={ typeof props.step === "undefined" ? 'inactive-step' : props.step === 1 ? 'current-step' : 'normal-step' }>Välj varumärke och produkt</div>
            <div className={ typeof props.step === "undefined" ? 'inactive-step' : props.step === 2 ? 'current-step' : 'normal-step' }>Ange antal returer</div>
            <div className={ typeof props.step === "undefined" ? 'inactive-step' : props.step === 3 ? 'current-step' : 'normal-step' }>Fyll i dina uppgifter</div>
        </div>
    );
}

export default CurrentStepIndicator;
