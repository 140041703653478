import React from 'react';
//import ProductList from '../components/ProductList';
import Checkout from '../components/Checkout';
//import FeedbackList from '../components/FeedbackList';
import CurrentStepIndicator from '../components/CurrentStepIndicator';

function Page() {
  return (
    <>
      <CurrentStepIndicator step={2} />
      <div className="wrapper submitReturn">
        <Checkout />
      </div>
    </>
  );
}

export default Page;
