import React from 'react';
import { Link, NavLink, withRouter } from 'react-router-dom';
import { useCart } from '../contexts/CartContext';
import logo from '../images/skruf_logo.svg';
import './AppHeader.scss';
import LanguageSelector from './LanguageSelector.js';

function NavItem(props) {
  const { isNavLink } = props;
  const { wasAdded, items, getItemCount } = useCart();

  const navClasses = ['NavItem'];

  if (props.isCart && wasAdded) {
    navClasses.push('item-added');
  }

  if (props.isCart && items.length > 0) {
    navClasses.push('cart-is-not-empty');
  }

  if (isNavLink) {
    return (
      <li className={navClasses.join(' ')}>
        <NavLink to={props.to} exact={props.exact}>
          {props.children}
          {props.isCart && <span className="NavItem-cartcount">{getItemCount()}</span>}
        </NavLink>
      </li>
    );
  } else {
    return (
      <li className="NavItem">
        <Link to={props.to}>{props.children}</Link>
      </li>
    );
  }
}

function AppHeader(props) {
  /*
  const [, signOut] = useAuthState();
  const [, setHasFeedback] = useFeedback();
  const { destroyCart } = useCart();
  */
  const { REACT_APP_SHOW_ANNOUNCEMENT } = process.env;

  // Simple function which clears the current users data...
  /*
  function clearUserData(event) {
    if (event.type === 'click') {
      setHasFeedback(true);
      destroyCart();
      signOut();
    } else if (event.type === 'keyPress' && event.key === 'Enter') {
      setHasFeedback(true);
      destroyCart();
      signOut();
    } else {
      // Do nothing!
    }
  }
  */

  return (
    <header
      className={`AppHeader ${
        props.location.pathname === '/' && REACT_APP_SHOW_ANNOUNCEMENT ? 'announcement' : ''
      }`}
    >
      <div className="wrapper">
        <Link to="/" className="AppLogo">
          <img src={logo} alt={'skruf symbol'} className="AppLogo-symbol" />
        </Link>
        <LanguageSelector simple={true} />
        <nav className="AppNavigation">
          <ul className="AppMenu">
            <>
              <NavItem to="/cart" exact={true} isNavLink={true} isCart={true}>
                <label class="hide-in-mobile">Returkorg</label>
                <svg className="icon icon-bag">
                  <use xlinkHref="#icon-bag"></use>
                </svg>
              </NavItem>
            </>
          </ul>
        </nav>
      </div>
    </header>
  );
}

export default withRouter(AppHeader);
