import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import React, { useEffect } from 'react';
import { I18nextProvider } from 'react-i18next';
import { HashRouter as Router, Route, Switch, useLocation } from 'react-router-dom';
import './App.scss';
import AppTimer from './AppTimer';
import AppHeader from './components/AppHeader';
import AuthProvider from './contexts/AuthContext';
import CartProvider from './contexts/CartContext';
import FeedbackProvider from './contexts/FeedbackContext';
import i18n from './i18n';
import Announcement from './pages/Announcement';
import Arabic from './pages/Arabic';
import Cart from './pages/Cart';
import English from './pages/English';
import Index from './pages/Index';
import LoggedIn from './pages/LoggedIn';
import Login from './pages/Login';
import LogoutPage from './pages/LogoutPage';
import NewPassword from './pages/NewPassword';
import NoMatch from './pages/NoMatch';
import ReportSubmitted from './pages/ReportSubmitted';
import Returns from './pages/Returns';
import SubmitReturn from './pages/SubmitReturn';
console.log(i18n);
function initLogRocket() {
  let LogRocketUnid = null;

  if (process.env.NODE_ENV === 'production') {
    LogRocketUnid = 'itbg2k/skrufreturer';
  } else {
    LogRocketUnid = 'itbg2k/skrufreturer';
  }
  LogRocket.init(LogRocketUnid, {
    network: {
      requestSanitizer: request => {
        // Ignore the url if it contains the word: "ignore"
        if (request.url.toLowerCase().indexOf('ignore') !== -1) {
          return null;
        }

        // No need to save "Authorization" headers
        if (request.headers['Authorization']) {
          request.headers['Authorization'] = '';
        }

        // otherwise log the request normally
        return request;
      },
    },
  });

  // Setup so that we can see react props/state etc
  setupLogRocketReact(LogRocket);
}

// Initiate log rocket
initLogRocket();

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

/**
 * Thw app main component, harbors all contexts, routes and usual components.
 */
function App(props) {
  document.body.dir = i18n.dir();

  return (
    <I18nextProvider i18n={i18n}>
      <AuthProvider>
        <FeedbackProvider>
          <CartProvider>
            <div className="App">
              <Router>
                <ScrollToTop />
                <AppTimer>
                  <AppHeader />
                  <Switch>
                    <Route path="/" component={Index} exact />
                    <Route path="/returns" component={Returns} exact />
                    <Route path="/submit" component={SubmitReturn} exact />
                    <Route path="/announcement" component={Announcement} exact />
                    <Route path="/arabic" component={Arabic} exact />
                    <Route path="/english" component={English} exact />
                    <Route path="/inloggad" component={LoggedIn} exact />
                    <Route path="/cart" component={Cart} exact />
                    <Route path="/tack" component={ReportSubmitted} exact />
                    <Route path="/logga-ut" component={LogoutPage} exact />
                    <Route path="/logga-in" component={Login} exact />
                    <Route path="/logga-in/pin/:userLogin" component={Login} exact />
                    <Route path="/logga-in/pin/:userLogin/ny" component={NewPassword} exact />
                    <Route
                      path="/logga-in/pin/:userLogin/ny/:resetKey"
                      component={NewPassword}
                      exact
                    />
                    <Route component={NoMatch} />
                  </Switch>
                </AppTimer>
              </Router>
            </div>
          </CartProvider>
        </FeedbackProvider>
      </AuthProvider>
    </I18nextProvider>
  );
}

export default App;
