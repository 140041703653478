import PropTypes from 'prop-types';
import React from 'react';
import { Redirect } from 'react-router-dom';
import Checkout from '../components/Checkout';
import ProductList from '../components/ProductList';
import { useAuthState } from '../contexts/AuthContext';

function LoggedIn({ match }) {
  const { isLoggedIn } = useAuthState();

  if (!isLoggedIn) {
    return <Redirect to="/logga-in" />;
  }

  return <>{match.path === '/inloggad' ? <ProductList /> : <Checkout />}</>;
}

LoggedIn.propTypes = {
  match: PropTypes.object.isRequired,
};

export default LoggedIn;
