import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Link } from 'react-router-dom';
import axios from 'axios';

import './NewPassword.scss';
import ForgotPasswordForm from '../components/ForgotPasswordForm';
import Loader from '../components/shared/Loader';
import { useAuthState } from '../contexts/AuthContext';

// Page compomnent for the "Forgot password" route
function NewPassword({ match }) {
  const { userLogin, resetKey } = match.params;
  const { isLoggedIn } = useAuthState();

  // Some important states for the component
  const [hasSentMail, setHasSentMail] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  function requestForgotPassword() {
    setIsLoading(true);
    setIsError(false);
    const { REACT_APP_API_URL } = process.env;
    async function sendPasswordMail() {
      try {
        const result = await axios.post(
          `${REACT_APP_API_URL}/skrufreturer/v1/reset-password/send`,
          {
            user: userLogin
          }
        );

        if (result.data) {
          setHasSentMail(true);
        }

        setIsLoading(false);
      } catch (error) {
        setIsError(true);
        setIsLoading(false);
      }
    }

    sendPasswordMail();
  }

  if (isLoggedIn) {
    return <Redirect to="/inloggad" />;
  }

  if (userLogin && resetKey) {
    return <ForgotPasswordForm userLogin={userLogin} resetKey={resetKey} />;
  } else if (userLogin) {
    if (hasSentMail) {
      return (
        <div className="ForgotPassword">
          <div className="wrapper">
            <h2 className="PageTitle">Mail skickat till din epostadress</h2>
            <p>
              Kontrollera i din inkorg efter ett mejl från Personalsnus. <br />
              Mejlet inkluderar en länkadress som du bör följa om du vill förnya
              din lösenkod.
            </p>
            <p>
              Har du problem att hitta mejlet? Titta även i din inkorgs
              skräppost.
            </p>
            <Link to="/logga-in" className="primary-button">
              Till startsidan
            </Link>
          </div>
        </div>
      );
    } else {
      return (
        <div className="ForgotPassword">
          <div className="wrapper">
            <h2 className="PageTitle">Glömt din 4 siffriga lösenkod?</h2>
            <p>
              För att initiera processen med att uppdatera din lösenkod, klicka
              på knappen nedan.{' '}
            </p>
            <p>
              <button
                className="primary-button"
                onClick={event => {
                  requestForgotPassword();
                  event.preventDefault();
                }}
                disabled={isLoading}
              >
                Skicka mail med instruktioner
              </button>
            </p>
            <p>
              <Link to={`/logga-in/pin/${userLogin}`}>
                Tillbaka till lösenkoden
              </Link>
            </p>
            {isLoading && <Loader />}
            {isError && (
              <p>
                Kunde inte bekräfta att återställningsmejlet skickades, var god
                och testa igen vid ett senare tillfälle. Om du fortfarande har
                problem, kontakta då en administratör angående problemet.
              </p>
            )}
          </div>
        </div>
      );
    }
  } else {
    return null;
  }
}

NewPassword.propTypes = {
  match: PropTypes.object.isRequired
};

export default NewPassword;
