import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import backend from "i18next-http-backend";
import LanguageDetector from 'i18next-browser-languagedetector';
import translationsToArabic from './translations/ar/translation.json'
import translationsToEnglish from './translations/en/translation.json'
import translationsToSwedish from './translations/sv/translation.json'

const resources = {
  en: {
    translation: translationsToEnglish
  },
  ar: {
    translation: translationsToArabic
  },
  sv: {
    translation: translationsToSwedish
  }
};

// Disable some languages to avoid them being picked
delete resources.en;
delete resources.ar;

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  .use(backend)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    react: {
        useSuspense: false,

    wait: false,
    },
    resources,
    debug: true,
    fallbackLng: 'sv',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    saveMissing: true
  });
export default i18n;