import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useCart } from '../contexts/CartContext';
import ProductImage from './shared/ProductImage';

/**
 * Child component for the cart list, renders a single cart item.
 */
function CartItem(props) {
  const { itemData } = props;
  const { count } = itemData;
  const { add, remove, setCount } = useCart();
  const [internalCount, setInternalCount] = useState(count);

  console.log(`Rendered Cart item: ${itemData.count}`, itemData);

  // Some product img media
  const [attachment] = itemData['_embedded']['wp:featuredmedia'] || [];
  const imgSizes = ['thumbnail', 'medium', 'medium_large'];

  // Return the render of the cart item (this should probably be divided into several child components)
  return (
    <div className={`CartItem ${props.itemHasError === true ? 'CartItem-has-errors' : ''}`}>
      {attachment && attachment.media_details && (
        <ProductImage
          alt={attachment.alt_text}
          src={attachment.media_details.sizes.thumbnail.source_url || ''}
          sizes={attachment.media_details.sizes}
          targetSizes={imgSizes}
        />
      )}
      <div className="CartItem-fields">
        <h2 className="CartItem-title">
          <strong>{props.itemGroup}</strong>
          {props.itemName}
        </h2>

        <div className="CartItem-field">
          <div className="CartItem-amount-inputs">
            <label htmlFor={`CartItem-Amount-Private-${props.itemData.id}`} className="simple-only">
              Antal:
            </label>
            {props.simple ? (
              <span className="simple-only cart-count">{count}</span>
            ) : (
              <input
                type="number"
                value={internalCount || ''}
                placeholder={0}
                min={0}
                max={999}
                name={`CartItem-Amount-Private-${props.itemData.id}`}
                id={`CartItem-Amount-Private-${props.itemData.id}`}
                onChange={event => {
                  let tempCount = parseInt(event.target.value, 10) || '';
                  tempCount = tempCount > 999 ? 999 : tempCount;

                  setInternalCount(tempCount);
                  setCount(itemData, tempCount);
                }}
              />
            )}
            <button
              className="CartItem-amount-button"
              title="Subtrahera 1 på antalet"
              onClick={event => {
                event.preventDefault(); // Prevent form from submitting
                setInternalCount(internalCount - 1);
                remove(itemData, 1);
              }}
            >
              <svg className="icon icon-minus bold">
                <use xlinkHref="#icon-minus"></use>
              </svg>
            </button>
            <button
              className="CartItem-amount-button"
              title="Addera 1 på antalet"
              onClick={event => {
                event.preventDefault(); // Prevent form from submitting
                setInternalCount(internalCount + 1);
                add(itemData, 1);
              }}
            >
              <svg className="icon icon-plus bold">
                <use xlinkHref="#icon-plus"></use>
              </svg>
            </button>
          </div>
        </div>

        <div className="CartItem-actions">
          <button
            className="CartItem-remove-button"
            title="Ta bort produkten från returkorgen"
            onClick={event => {
              remove(props.itemData);
              event.preventDefault();
            }}
          >
            <svg className="icon icon-cross2">
              <use xlinkHref="#icon-cross2"></use>
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
}

CartItem.propTypes = {
  itemData: PropTypes.object.isRequired,
  itemGroup: PropTypes.string.isRequired,
  itemName: PropTypes.string.isRequired,
  itemId: PropTypes.number.isRequired,
};

export default CartItem;
