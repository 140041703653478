import React, { useRef } from 'react';
import IdleTimer from 'react-idle-timer';

import { useAuthState } from './contexts/AuthContext';
import { useCart } from './contexts/CartContext';
import { useFeedback } from './contexts/FeedbackContext';

const secondsIdle = 1000 * 40;
const enableLogout = false;

export default function AppTimer({ children }) {
  const idleTimer = useRef(null);
  const { signOut, isLoggedIn } = useAuthState();
  const { items, destroyCart } = useCart();
  const { setHasFeedback } = useFeedback();

  function onIdle(e) {
    console.log('user is idle');
    if(!enableLogout) return;

    // Set has feedback to true, otherwise it will not query it
    setHasFeedback(true);

    // If user is logged in, logout the user
    if (isLoggedIn) {
      signOut();
    }

    // If cart is not empty, destroy the cart (empty the cart contents)
    if (items.length > 0) {
      destroyCart();
    }
  }

  return (
    <>
      <IdleTimer
        ref={idleTimer}
        element={document}
        onIdle={onIdle}
        debounce={300}
        timeout={secondsIdle}
      />
      {children}
    </>
  );
}
