import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './Index.scss';

function Page(props) {
  const { t } = useTranslation();

  useEffect(() => {}, []);

  return (
    <>
      <div className="wrapper fat-gutter">
        <article className="text-flow content">
          <h1 className="title">{t('page.announcement.covid19.title')}</h1>
          <p className="preamble">{t('page.announcement.covid19.preamble')}</p>

          <p>{t('page.announcement.covid19.paragraph1')}</p>
        </article>
      </div>
    </>
  );
}

export default Page;
