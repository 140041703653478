import axios from 'axios';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import * as Yup from 'yup';
import Checkout from '../components/Checkout';
import CurrentStepIndicator from '../components/CurrentStepIndicator';
import LinkButton from '../components/LinkButton';
import { useCart } from '../contexts/CartContext';
import './SubmitReturn.scss';

const Form = props => {
  const formik = useFormik({
    initialValues: {
      storeNumber: '',
      storeName: '',
      organisationNumber: '',
      payoutDestination: '',
      payoutDestinationNumber: '',
      postageSlips: '',
      slipDestination: 'test',
      email: 'a@a.a',
      ageCheck: '',
    },
    validationSchema: Yup.object({
      /*firstName: Yup.string()
        .max(15, 'Must be 15 characters or less')
        .required('Required'),
      lastName: Yup.string()
        .max(20, 'Must be 20 characters or less')
        .required('Required'),
      //email: Yup.string().email('Invalid email address').required('Required'),
      */
    }),
    onSubmit: values => {
      console.log('1.', values);
      props.handleSubmit(values);
    },
  });
  return (
    <form onSubmit={formik.handleSubmit} className="CartList-form simple">
      <label htmlFor="storeNumber">Swedish Match kund eller butiksnummer*</label>
      <input
        type="text"
        id="storeNumber"
        name="storeNumber"
        {...formik.getFieldProps('storeNumber')}
      />

      <label htmlFor="storeName">Butiksnamn*</label>
      <input type="text" id="storeName" name="storeName" {...formik.getFieldProps('storeName')} />

      <label htmlFor="organisationNumber">Organisationsnummer*</label>
      <input
        type="text"
        id="organisationNumber"
        name="organisationNumber"
        {...formik.getFieldProps('organisationNumber')}
      />

      <label>Konto för utbetalning*</label>
      <div className="flex-vertical-center">
        <input
          type="radio"
          className="styled-radio"
          value="Bankgiro"
          name="payoutDestination"
          id="payoutDestination_1"
          {...formik.getFieldProps('payoutDestination')}
        />
        <label className="styled-radio" htmlFor="payoutDestination_1">
          <span>Bankgiro</span>
        </label>
        <input
          type="radio"
          className="styled-radio"
          value="Plusgiro"
          name="payoutDestination"
          id="payoutDestination_2"
          {...formik.getFieldProps('payoutDestination')}
        />
        <label className="styled-radio" htmlFor="payoutDestination_2">
          <span>Plusgiro</span>
        </label>
        <input
          type="radio"
          className="styled-radio"
          value="Annat"
          name="payoutDestination"
          id="payoutDestination_3"
          {...formik.getFieldProps('payoutDestination')}
        />
        <label className="styled-radio" htmlFor="payoutDestination_3">
          <span>Annat</span>
        </label>
      </div>

      <label htmlFor="payoutDestinationNumber">Nr*:</label>
      <input
        type="text"
        id="payoutDestinationNumber"
        name="payoutDestinationNumber"
        {...formik.getFieldProps('payoutDestinationNumber')}
      />

      <label htmlFor="postageSlips">Antal fraktsedlar*</label>
      <input
        type="text"
        id="postageSlips"
        name="postageSlips"
        {...formik.getFieldProps('postageSlips')}
      />

      <label>Hur vill du ha fraktsedlarna?*</label>
      <div className="flex-vertical-center">
        <input
          className="styled-radio"
          type="radio"
          name="slipDestination"
          id="slipDestination_1"
          {...formik.getFieldProps('slipDestination')}
        />
        <label className="styled-radio" htmlFor="slipDestination_1">
          <span>E-post</span>
        </label>
        <input
          className="styled-radio"
          type="radio"
          name="slipDestination"
          id="slipDestination_2"
          {...formik.getFieldProps('slipDestination')}
        />
        <label className="styled-radio" htmlFor="slipDestination_2">
          <span>Post</span>
        </label>
      </div>

      <label htmlFor="email">E-post*</label>
      <input type="email" id="email" name="email" {...formik.getFieldProps('email')} />

      <div className="flex-vertical-center">
        <input
          className="styled-checkbox"
          type="checkbox"
          id="ageCheck"
          name="ageCheck"
          {...formik.getFieldProps('ageCheck')}
        />
        <label className="styled-checkbox" htmlFor="ageCheck">
          Jag är över 18*
        </label>
      </div>

      {formik.touched.email && formik.errors.email ? (
        <div className="error-message">{formik.errors.email}</div>
      ) : null}

      <div className="flex-center">
        <LinkButton disabled={props.isLoading} className="outlined-button" to="/cart">
          Tillbaka
        </LinkButton>
        <button disabled={props.isLoading} className="primary-button" type="submit">
          Skicka
        </button>
      </div>
    </form>
  );
};

function Page() {
  const [Toggled, setToggled] = React.useState(
    localStorage.getItem('Toggled') === 'true' ? true : false,
  );

  // Which contexts to consume
  const { items, destroyCart } = useCart();

  // Several states
  const [, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const SaveStateListener = () => {
    localStorage.setItem('Toggled', Toggled);
  };

  useEffect(SaveStateListener, [Toggled]);

  /**
   * Function which handles the cart list form submit and post request to the API.
   * @param {*} event
   */
  function handleSubmit(values) {
    // Prevent page reload upon submit
    console.log('Cart items:', items);
    console.log('values:', JSON.stringify(values, null, 2));

    setIsSubmitted(true);

    const itemData = items.map(item => {
      // If the  user is an subscriber = works in the office and takes products from the refrigerator (ID: 104)
      // If the user is an seller = it always counts as taking products from their own storage (their own employee ID)
      // If the user is an key account manager (kam) = they can select which storage they can take from (select employee ID)

      return {
        productId: item.id,
        productNr: item.product_nr,
        count: item.count,
      };
    });

    console.log('Forms itemdata:', itemData);

    // Set the different states
    setIsError(false);
    setIsLoading(true);

    const { REACT_APP_API_URL } = process.env;
    async function postItems() {
      try {
        const endpoint = `${REACT_APP_API_URL}/skrufreturer/v1/returns`;

        const result = await axios.post(endpoint, {
          details: values,
          items: itemData,
        });
        if (result.data) {
          setIsLoading(false);
          setIsSubmitted(true);
          destroyCart();
        }
      } catch (error) {
        console.error(error);
        setIsLoading(false);
        setIsError(true);
      }
    }

    // Post the items data towards the API
    postItems();
  }

  useEffect(() => {
    if (isSubmitted && !isLoading) {
      destroyCart();
    }
  }, [isSubmitted, destroyCart, isLoading]);

  if (isSubmitted && !isLoading) {
    return <Redirect to="/tack" />;
  }

  return (
    <>
      <CurrentStepIndicator step={3}></CurrentStepIndicator>
      <div className="wrapper submitReturn">
        <h3
          id="returns-headline"
          onClick={() => {
            setToggled(!Toggled);
          }}
        >
          Se alla dina returer
          <svg id="showReturns" style={{ transform: Toggled ? 'rotate(0deg)' : 'rotate(90deg)' }}>
            <use xlinkHref="#icon-arrow-filled"></use>
          </svg>
        </h3>
        {Toggled && <Checkout simple={true} />}

        <div className="content">
          <h1>Fyll i dina uppgifter</h1>

          <p>*Obligatoriska uppgifter</p>

          <Form handleSubmit={handleSubmit} />
        </div>
      </div>
    </>
  );
}

export default Page;
