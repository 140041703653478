// Simple child component for adding / removing products from the cart context
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useCart } from '../contexts/CartContext';
import ProductImage from './shared/ProductImage';

function ProductItem(props) {
  const [internalCount, setInternalCount] = useState(0);
  const { itemData, children } = props;
  const { add, amountInCart } = useCart();

  console.log('Rendered ProductItem with itemData', itemData);
  //console.log(`Rendered Product Item: `, itemData, internalCount)

  const [attachment] = itemData['_embedded']['wp:featuredmedia'] || [];
  const imgSizes = ['thumbnail', 'medium', 'medium_large'];

  return (
    <div className="ProductItem">
      <div className="item-wrapper">
        {attachment && attachment.media_details && (
          <ProductImage
            alt={attachment.alt_text}
            src={attachment.media_details.sizes.thumbnail.source_url || ''}
            sizes={attachment.media_details.sizes}
            targetSizes={imgSizes}
          />
        )}
        {children}

        <div className="ProductItem-fields">
          <label htmlFor={`CartItem-Amount-Private-${props.itemData.id}`}>Antal:</label>

          <input
            type="number"
            value={internalCount || ''}
            placeholder={0}
            min={0}
            max={999}
            name={`CartItem-Amount-Private-${props.itemData.id}`}
            id={`CartItem-Amount-Private-${props.itemData.id}`}
            onChange={event => {
              const tempCount = parseInt(event.target.value, 10) || 0;

              setInternalCount(tempCount);
            }}
          />

          <button
            className="ProductItem-button button-cart-remove"
            onClick={event => {
              let value = Math.max(Number(0), Math.min(Number(999), Number(internalCount - 1)));
              setInternalCount(value);
            }}
          >
            <svg className="icon icon-minus bold">
              <use xlinkHref="#icon-minus"></use>
            </svg>
          </button>

          <button
            className="ProductItem-button button-cart-add"
            onClick={event => {
              let value = Math.max(Number(0), Math.min(Number(999), Number(internalCount + 1)));
              setInternalCount(value);
            }}
          >
            <svg className="icon icon-plus bold">
              <use xlinkHref="#icon-plus"></use>
            </svg>
          </button>

          <button
            className="ProductItem-button button-cart-goto"
            onClick={event => {
              add(itemData, internalCount);
              setInternalCount(0);
            }}
          >
            <span className="NavItem-cartcount">{amountInCart(itemData)}</span>
            <svg className="icon icon-bag">
              <use xlinkHref="#icon-bag"></use>
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
}

ProductItem.propTypes = {
  children: PropTypes.element,
  itemData: PropTypes.object.isRequired,
};

export default ProductItem;
